import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Error = () => {
  return (
    <Layout>
      <SEO siteTitle='Page Not Found' />
      <main
        className='flex flex-col md:flex-row justify-center items-center md:p-40 py-16 px-8 my-10'
        style={{ minHeight: '76.4vh' }}
      >
        <StaticImage
          src='../images/404.png'
          alt='404 Indicator'
          placeholder='blurred'
          className='w-84 md:w-96 mb-16 md:mb-0 mr-0 md:mr-40'
        />
        <div className='flex flex-col items-center md:items-start'>
          <p className='text-lg w-full md:w-96 mb-4'>
            Oops! An Error Occurred. You can go Back to the Homepage by clicking
            the button below
          </p>
          <Link to='/'>
            <button className='bg-yellow-500 px-8 py-4 flex justify-center items-center focus:outline-none font-semibold rounded-full  text-yellow-50 transform transition hover:shadow-lg hover:text-white hover:scale-95'>
              Go to the Homepage
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5 ml-1'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </Link>
        </div>
      </main>
    </Layout>
  );
};

export default Error;
